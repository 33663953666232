.nav-header {
    display: flex;
    justify-content: center;
    background-color: #202020;
  }
  
  .nav-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    max-width: 1110px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  
  .nav-bar-mobile-logo-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  @media screen and (min-width: 768px) {
    .nav-bar-mobile-logo-container {
      display: none;
    }
  }
  
  .nav-bar-large-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1110px;
  }
  
  @media screen and (max-width: 767px) {
    .nav-bar-large-container {
      display: none;
    }
  }
  
  .website-logo {
    width: 90px;
  }
  
  @media screen and (min-width: 768px) {
    .website-logo {
      width: 125px;
    }
  }
  
  .nav-menu {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-self: flex-end;
    list-style-type: none;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  
  @media screen and (max-width: 767px) {
    .nav-menu {
      display: none;
    }
  }
  
  .nav-menu-item {
    font-family: 'Roboto';
    text-decoration: none;
    margin: 10px;
    font-weight: 400;
    font-size: 16px;
  }
  
  .nav-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #ffffff;
  }
  
  .logout-desktop-btn {
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 14px;
    color: #ffffff;
    background-color: #4f46e5;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
    padding: 12px 42px;
  }
  
  @media screen and (max-width: 767px) {
    .logout-desktop-btn {
      display: none;
    }
  }
  .nav-bar-mobile-icons-container {
    display: flex;
    align-items: center;
    list-style-type: none;
  }
  
  .nav-mobile-btn {
    border: none;
    padding: 0px;
    background: transparent;
    cursor: pointer;
    outline: none;
    font-size: 24px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .nav-item-mobile-link {
    font-size: 24px;
    color: #ffffff;
    margin-right: 20px;
  }
  
  @media screen and (min-width: 768px) {
    .nav-mobile-btn {
      display: none;
    }
  }
  