.not-found-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90vh;
    background-color: #000000;
    padding-bottom: 40px;
    padding-top: 90px;
  }
  .not-found-img {
    width: 300px;
  }
  .not-found-heading {
    font-size: 34px;
    font-family: 'Roboto';
    font-weight: 700;
    color: #ffffff;
    text-align: center;
    padding-top: 15px;
  }
  .not-found-description {
    font-size: 16px;
    font-family: 'Roboto';
    color: #ffffff;
    text-align: center;
  }
  @media screen and (min-width: 768px) {
    .not-found-heading {
      font-size: 42px;
    }
    .not-found-description {
      font-size: 18px;
    }
  }
  