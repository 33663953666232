.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #000000;
    height: 100vh;
  }
  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 20px;
    border-radius: 8px;
    width: 100%;
    max-width: 450px;
    background-color: #202020;
  }
  
  @media screen and (min-width: 992px) {
    .form-container {
      width: 450px;
      flex-shrink: 0;
      padding: 64px 48px 64px 48px;
    }
  }
  .login-website-logo {
    width: 90px;
    padding-bottom: 20px;
  }
  @media screen and (min-width: 768px) {
    .login-website-logo {
      width: 180px;
    }
  }
  .input-container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    width: 100%;
  }
  
  .input-label {
    margin-bottom: 0px;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #ffffff;
  }
  
  .username-input-field {
    font-size: 15px;
    height: 40px;
    border: 1px solid #6366f1;
    background-color: transparent;
    color: #ffffff;
    border-radius: 2px;
    margin-top: 5px;
    padding: 8px 16px 8px 16px;
    outline: none;
  }
  
  .password-input-field {
    font-size: 14px;
    height: 40px;
    border: 1px solid #6366f1;
    background-color: transparent;
    color: #ffffff;
    border-radius: 2px;
    margin-top: 5px;
    padding: 8px 16px 8px 16px;
    outline: none;
  }
  
  .login-button {
    font-family: 'Roboto';
    font-weight: bold;
    font-size: 16px;
    color: #ffffff;
    height: 40px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 2px;
    background-color: #4f46e5;
    border-radius: 8px;
    border: none;
    outline: none;
    cursor: pointer;
  }
  
  .error-message {
    align-self: start;
    font-size: 12px;
    margin-top: 3px;
    margin-bottom: 0px;
    font-family: 'Roboto';
    font-size: 12px;
    line-height: 16px;
    color: #ff0b37;
  }
  